<template>
  <div>
    <div
      style="
        display: flex;
        justify-content: space-between;
        background: #fff;
        padding: 10px; 
      "
    >
      <div class="title">客户统计</div>
      <div class="search-div">
        <a-form :model="searchForm" layout="inline">
          <a-form-item label="">
            <a-select
              v-model:value="searchForm.sortParam"
              placeholder="排名类型"
              style="width: 120px"
            >
              <a-select-option :value="1">台量</a-select-option>
              <a-select-option :value="2">总金额</a-select-option>
              <a-select-option :value="3">总利润</a-select-option>
              <a-select-option :value="4">应收金额</a-select-option>
              <a-select-option :value="5">逾期金额</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="">
            <a-select
              v-model:value="searchForm.sort"
              placeholder="降序/升序"
              style="width: 120px"
            >
              <a-select-option :value="1">降序</a-select-option>
              <a-select-option :value="2">升序</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="">
            <a-select v-model:value="searchForm.coopType" :options="$store.state.app.labelAll.DEAL" placeholder="成交状态">
            </a-select>
          </a-form-item>
          <a-form-item label="">
            <a-select v-model:value="searchForm.coopOldType" :options="$store.state.app.labelAll.COOP" placeholder="合作状态">
            </a-select>
          </a-form-item>
          <a-form-item label="">
            <a-input v-model:value="searchForm.customerName" placeholder="客户名称" />
          </a-form-item>
          <a-form-item label="">
            <a-input v-model:value="searchForm.saleName" placeholder="业务员" />
          </a-form-item>
          <a-form-item label="">
            <a-space>
              <a-button type="primary" @click="searchData">搜索</a-button>
              <a-button @click="resetData">重置</a-button>
            </a-space>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-table
      size="small"
      :columns="columns"
      :row-key="(record) => record.rank"
      :data-source="listData"
      :scroll="{ x: 1500, y: 600 }"
      bordered
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <template #customerName="{ record }">
        <span>
          <a @click="onDetail(record)">
            <span v-if="record.customerName !== ''">{{ record.customerName }}</span>
            <span v-else> 无</span>
          </a>
        </span>
      </template>
      <template #statistics="{ record }">
        <div><a @click="openLook(record.customerId)">年度统计</a></div>
      </template>
    </a-table>
  </div>
  <a-modal v-model:visible="openStatisticsShow" width="60%" title="统计信息" footer="" destroyOnClose>
    <div>
      <div style="display: flex; justify-content: space-between">
<!--        <a-date-picker-->
<!--          v-model:value="year"-->
<!--          mode="year"-->
<!--          :open="yearShow"-->
<!--          @change="monthChange"-->
<!--          @click="yearClick"-->
<!--          @panelChange="panelChangeOne "-->
<!--          format="YYYY"-->
<!--        />-->
        <a-select
          v-model:value="year"
          placeholder="选择年"
          style="width: 120px"
          @change="monthChange"
        >
          <a-select-option value="2024">2024年度</a-select-option>
          <a-select-option value="2023">2023年度</a-select-option>
          <a-select-option value="2022">2022年度</a-select-option>
          <a-select-option value="2021">2021年度</a-select-option>
        </a-select>
        <a-radio-group v-model:value="typeChange" button-style="solid" @change="typeChangeClick">
          <a-radio-button :value="1">台量统计</a-radio-button>
          <a-radio-button :value="2">运费统计</a-radio-button>
        </a-radio-group>
      </div>
      <a-spin tip="加载中..."  :spinning="spinning">
        <div id="numStatstics"  style="width: 100%;" ></div>
      </a-spin>
    </div>
  </a-modal>
  <a-drawer title="详情" width="80%" placement="right" v-model:visible="detailShow" destroyOnClose>
    <CustomerDetail v-model:customerId="customerId" type="2" num="6"/>
  </a-drawer>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { getDataPage, customerCurve } from '@/api/dataCenter/customerStatistics'
import CustomerDetail from '../../crmManagement/tool/customerDetail/index'
import { customerCurveLoadEcharts } from './customerEcharts'
export default {
  components: {
    CustomerDetail
  },
  setup () {
    const state = reactive({
      openStatisticsShow: false,
      yearShow: false,
      year: null,
      typeChange: 1,
      loading: false,
      spinning: false,
      customerId: undefined,
      detailShow: false,
      searchForm: {
        sortParam: null,
        customerName: '',
        department: null,
        salesman: null
      },
      listData: [],
      dataList: [],
      columns: [
        {
          title: '排名',
          dataIndex: 'rankNum',
          align: 'center'
        },
        {
          title: '客户名称',
          dataIndex: 'customerName',
          align: 'center',
          slots: {
            customRender: 'customerName'
          }
        },
        {
          title: '业务员',
          dataIndex: 'saleName',
          align: 'center'
        },
        {
          title: '客户类型',
          dataIndex: 'customerType.label',
          align: 'center'
        },
        {
          title: '台量等级',
          dataIndex: 'vehicleGrade.label',
          align: 'center'
        },
        {
          title: '质量等级',
          dataIndex: 'qualityGrade.label',
          align: 'center'
        },
        {
          title: '风险等级',
          dataIndex: 'riskGrade.label',
          align: 'center'
        },
        {
          title: '累计台量',
          dataIndex: 'totalTransVehicle',
          align: 'center'
        },
        {
          title: '小程序量',
          dataIndex: 'miniCarNum',
          align: 'center'
        },,
        {
          title: '质损台量',
          dataIndex: 'massLossNum',
          align: 'center'
        },
        {
          title: '合同金额',
          dataIndex: 'totalTransFee',
          align: 'center'
        },,
        {
          title: '返款金额',
          dataIndex: 'accumulatedRefund',
          align: 'center'
        },
        {
          title: '应收金额',
          dataIndex: 'amountReceivable',
          align: 'center'
        },
        {
          title: '逾期金额',
          dataIndex: 'overdueTotalAmt',
          align: 'center'
        },
        {
          title: '总利润',
          dataIndex: 'totalGrossProfit',
          align: 'center'
        },
        {
          title: '首次时间',
          dataIndex: 'firstTransTime',
          align: 'center'
        },
        {
          title: '最近时间',
          dataIndex: 'lastTransTime',
          align: 'center'
        },
        {
          title: '最近金额',
          dataIndex: 'lastTransFee',
          align: 'center'
        },
        {
          title: '百万车辆',
          dataIndex: 'luxuryCarNum',
          align: 'center'
        },
        {
          title: '统计',
          dataIndex: 'statistics',
          align: 'center',
          slots: {
            customRender: 'statistics'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '40', '100'],
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      loadData()
    })
    const loadData = () => {
      state.loading = true
      getDataPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
        .then((res) => {
          if (res.code === 10000) {
            state.listData = res.data.records
            state.pagination.total = res.data.total
          }
        })
        .finally(() => {
          state.loading = false
        })
    }
    const customerCurveLoad = (customerId, year) => {
      console.log('customerId', customerId);
      customerCurve({
        customerId: customerId,
        year: year === null ? '2024' : year
      }).then((res) => {
        if (res.code === 10000) {
          console.log(res.data);
          state.dataList = res.data
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const handleTableChange = (page) => {
      state.pagination = page
      loadData()
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.searchForm = {
        sortParam: null,
        customerName: '',
        department: null,
        salesman: null
      }
      searchData()
    }
    const openLook = async (customerId) => {
      state.openStatisticsShow = true
      state.spinning = true
      state.year = '2024'
      state.typeChange = 1
      state.customerId = customerId
      await customerCurveLoad(customerId, null)
      setTimeout(() => {
        customerCurveLoadEcharts('numStatstics', state.dataList, 1)
        state.spinning = false
      }, 1000)
    }
    const monthChange = async () => {
      state.spinning = true
      await customerCurveLoad(state.customerId, state.year)
      setTimeout(() => {
        customerCurveLoadEcharts('numStatstics', state.dataList, state.typeChange)
        state.spinning = false
      }, 1000)
    }
    const typeChangeClick = async () => {
      customerCurveLoadEcharts('numStatstics', state.dataList, state.typeChange)
    }
    // 得到年份选择器的值
    const panelChangeOne = () => {
      state.yearShow = false
    }
    const yearClick = () => {
      state.yearShow = true
    }
    const onDetail = (e) => {
      state.detailShow = true
      state.customerId = e.customerId
    }
    return {
      ...toRefs(state),
      loadData,
      openLook,
      resetData,
      searchData,
      customerCurveLoad,
      onDetail,
      monthChange,
      typeChangeClick,
      yearClick,
      panelChangeOne,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
